.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  /* min-height: 80vh; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.heroSlider {

  & .searchBarSlider {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin: 28px auto 0 auto;
    /* width: fit-content; */
    gap: 0;
    padding: 6px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    width: 80%;
    background-color: #ffffff;

    @media(max-width: 1200px) {
      grid-template-columns: 25% 25% 25% 21%;
    }

    @media(max-width: 960px) {
      /* flex-wrap: wrap; */
      /* gap: 10px; */
      /* flex-direction: column; */
      width: 60%;
      margin: 40px auto 0;
      grid-template-columns: 100%;
    }

    @media(max-width: 768px) {
      width: 80%;
    }

    @media(max-width: 500px) {
      width: 94%;
    }

    & .locationInput {
      position: relative;

      @media(max-width: 960px) {
        width: 100%;
      }

      & .searchIcon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 9;
      }

      & .inputBox {
        & input {
          border: none;
          padding-left: 35px;
          background-position: center left 7px;
          background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%22-0.063%20-0.063%200.6%200.6%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20preserveAspectRatio%3D%22xMinYMin%22%20className%3D%22jam%20jam-search%22%3E%3Cpath%20d%3D%22M0.2%200.35A0.15%200.15%200%201%200%200.2%200.05a0.15%200.15%200%200%200%200%200.3zm0.158%20-0.027%200.09%200.09a0.025%200.025%200%201%201%20-0.035%200.035l-0.09%20-0.09a0.2%200.2%200%201%201%200.035%20-0.035z%22%20fill%3D'%23696969'%20%2F%3E%3C%2Fsvg%3E");

          @media(max-width: 960px) {
            height: 50px;
          }
        }
      }
    }

    & .selectDate {
      /* width: 305px; */

      @media(max-width: 1150px) {
        width: auto;
      }

      @media(max-width: 960px) {
        width: 100%;
      }

      & .datePickerInput {
        &>div {
          &>div {
            &>div {
              &>div {
                &>div {
                  background-color: #0000;

                  & input {
                    border: none;
                    padding-left: 35px;
                    background-position: 10px center;

                    &:hover {
                      /* border: 1px solid #d8dce6 !important; */
                    }

                    @media(max-width: 960px) {
                      height: 50px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    & .dropdownBox {
      position: relative;

      @media(max-width: 960px) {
        width: 100%;
      }

      & .inputBox {
        & select {
          height: 48px;
          padding: 6px 6px 6px 35px;
          box-shadow: none;

          @media(max-width: 960px) {
            height: 50px;
          }
        }
      }

      & .peopleIcon {
        top: 50%;
        z-index: 9;
        left: 10px;
        position: absolute;
        transform: translate(0, -50%);
        z-index: 0;
      }
    }
  }
}

.submitButton {
  /* width: 56%;
  margin: 40px auto 0; */
  Padding-left: 20px;

  @media(max-width:768px) {
    /* width: 75%; */
    padding-left: 0;
  }

  @media(min-width: 960px) {
    /* width: 300px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    margin-top: 110px; */
  }

  @media(max-width:430px) {
    /* width: 100%;
    padding: 0 30px; */
  }

  & button {
    height: 48px;
    color: #fff;
    font-size: 18px;
    min-height: 48px;
    padding: 5px 30px;
    border-radius: 10px;
    background-color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);

    &:hover,
    &:focus,
    &:disabled {
      background-color: var(--marketplaceColor);
    }

    @media(max-width: 960px) {
      width: 100%;
      height: 40px;
      font-size: 16px;
    }

    @media(max-width: 768px) {
      min-height: 55px;
    }
  }
}

.customDropdown {
  position: relative;

  & .customError {
    margin-top: 10px;
    margin-bottom: 0;
    color: var(--colorFail);
    overflow: hidden;
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
    letter-spacing: 1px;
  }

  & .dropdownSelectInput {
    width: 100%;
    height: 48px;
    outline: none;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    box-shadow: none;
    border-radius: 6px;
    align-items: center;
    background-size: 18px;
    background-color: #fff;
    padding: 6px 6px 6px 15px;
    border: 1px solid #d8dce6;
    background-repeat: no-repeat;
    background-position: 96% 13px;
    transition: all ease-in-out 100ms;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAAAXNSR0IArs4c6QAAAk5JREFUaEPtl7FLllEUxn8KDaG4OTa0CLYkkZHQkEsOQtDmENEcQTY0JSRimxgE/QHR5KaTULnZUkkNDba0NDgIQhBERNn7gIIk3/eec973XuTrnvV7nvOc55z7vffcPnos+nrMD8XQSZ9omVCZUOYOlCOXueFuuTIhd8syE8qEMjfcLfffTegZcMfdprSEx8BcJ4m6CX0HBtLW586+DYxGDT0EFt2SaQm3gBdRQ+KtAdfT1mjOrr/A3W7ouiMn7mngPXDOLJsGuAlcBX43NST+GWALGE5Ta23Wr8AYsFeHtEzoMMc48AY4VZe05d9/AJeAT5a8HkPKd7PbH9IiGMDcqKazauV5DSnvMnDfKtAQtwA88uSIGOoHXgOTHqEAdh2Yrgzte7gRQ8o/VIm9A0Y8Yg7sZ+AioIvdFVFDEjkLfDww5xKtAetLpg/Ql0jSJoakp2On46dj2EbojtFdozsnFE0NSXQWeBJSP066BzxtkqsNQ9LXbqVPepN4DtxukkDctgzpstWlq7MfibfV0b0C/IqQj3LaMqScWou0HmlN8sQOcB7Y9ZA6Yds0JA0tsFpktdBa4icwAXywgC2Ytg1JU08NPTksMVM91lYsQCsmhSFpa12ZryliCXhgLdSKS2VI+t0ehhvANeCPtVArLqWhTg9DbQAXqgX3m7VIDy6lIdXx78NQu5l2NO1qSSK1IRV9GXgFDAJTwMskTg6S5jCUsv5juYuhrO0OiJUJBZqWlVImlLXdAbEyoUDTslLKhLK2OyBWJhRoWlZKz03oL8EJPTWt9yLyAAAAAElFTkSuQmCC");

    @media(max-width: 960px) {
      height: 50px;
    }

    & .labelBoxInput {
      position: relative;
    }
  }

  & .dropdownSelect {
    width: 100%;
    height: 48px;
    outline: none;
    border: none !important;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    box-shadow: none;
    border-radius: 6px;
    align-items: center;
    background-size: 18px;
    background-color: #fff;
    padding: 6px 6px 6px 35px;
    border: 1px solid #d8dce6;
    /* background-repeat: no-repeat;
    background-position: 96% 13px;
    transition: all ease-in-out 100ms;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAAAXNSR0IArs4c6QAAAk5JREFUaEPtl7FLllEUxn8KDaG4OTa0CLYkkZHQkEsOQtDmENEcQTY0JSRimxgE/QHR5KaTULnZUkkNDba0NDgIQhBERNn7gIIk3/eec973XuTrnvV7nvOc55z7vffcPnos+nrMD8XQSZ9omVCZUOYOlCOXueFuuTIhd8syE8qEMjfcLfffTegZcMfdprSEx8BcJ4m6CX0HBtLW586+DYxGDT0EFt2SaQm3gBdRQ+KtAdfT1mjOrr/A3W7ouiMn7mngPXDOLJsGuAlcBX43NST+GWALGE5Ta23Wr8AYsFeHtEzoMMc48AY4VZe05d9/AJeAT5a8HkPKd7PbH9IiGMDcqKazauV5DSnvMnDfKtAQtwA88uSIGOoHXgOTHqEAdh2Yrgzte7gRQ8o/VIm9A0Y8Yg7sZ+AioIvdFVFDEjkLfDww5xKtAetLpg/Ql0jSJoakp2On46dj2EbojtFdozsnFE0NSXQWeBJSP066BzxtkqsNQ9LXbqVPepN4DtxukkDctgzpstWlq7MfibfV0b0C/IqQj3LaMqScWou0HmlN8sQOcB7Y9ZA6Yds0JA0tsFpktdBa4icwAXywgC2Ytg1JU08NPTksMVM91lYsQCsmhSFpa12ZryliCXhgLdSKS2VI+t0ehhvANeCPtVArLqWhTg9DbQAXqgX3m7VIDy6lIdXx78NQu5l2NO1qSSK1IRV9GXgFDAJTwMskTg6S5jCUsv5juYuhrO0OiJUJBZqWlVImlLXdAbEyoUDTslLKhLK2OyBWJhRoWlZKz03oL8EJPTWt9yLyAAAAAElFTkSuQmCC); */

    @media(max-width: 960px) {
      height: 50px;
    }

    & .labelBox {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: -16px;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);
        background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%200.9%200.9%22%20version%3D%221.1%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23696969%22%20d%3D%22M0.45%200.425a0.175%200.175%200%201%200%20-0.175%20-0.175%200.175%200.175%200%200%200%200.175%200.175Zm0%20-0.3a0.125%200.125%200%201%201%20-0.125%200.125%200.125%200.125%200%200%201%200.125%20-0.125Z%22%20class%3D%22clr-i-outline%20clr-i-outline-path-1%22%3E%3C%2Fpath%3E%3Cpath%20fill%3D%22%23696969%22%20d%3D%22M0.762%200.609a0.429%200.429%200%200%200%20-0.623%200A0.05%200.05%200%200%200%200.125%200.643V0.775a0.05%200.05%200%200%200%200.05%200.05h0.55a0.05%200.05%200%200%200%200.05%20-0.05v-0.132a0.05%200.05%200%200%200%20-0.013%20-0.034ZM0.725%200.775H0.175v-0.132a0.379%200.379%200%200%201%200.55%200Z%22%20class%3D%22clr-i-outline%20clr-i-outline-path-2%22%3E%3C%2Fpath%3E%3Cpath%20x%3D%220%22%20y%3D%220%22%20width%3D%2236%22%20height%3D%2236%22%20fill-opacity%3D%220%22%20d%3D%22M0%200H0.9V0.9H0V0z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
      }
    }

    & .defaultLabel {
      color: var(--colorGrey300);
    }

    & .peopleIcon {
      top: 50%;
      z-index: 9;
      left: 10px;
      position: absolute;
      transform: translate(0, -50%);
    }
  }

  & .guestCountUl {
    left: 0;
    right: 0;
    margin: 0;
    z-index: 9;
    width: 100%;
    padding: 20px 0;
    margin-top: -5px;
    position: absolute;
    border-radius: 0 0 4px 4px;
    background-color: var(--marketplaceColor);

    & li {
      padding: 10px 25px;
      cursor: pointer;
      color: #d5d5d5;
      position: relative;

      &:hover {
        color: #fff;

        &:before {
          top: 0;
          left: 0;
          width: 6px;
          content: "";
          height: 100%;
          position: absolute;
          background-color: #fff;
        }
      }
    }
  }
}

.timeDropdownWrapper {
  background-color: #fff;
  width: 100%;
  height: 48px;
  min-height: 48px;
  border-radius: 6px;
  border: 1px solid #d8dce6;
  position: relative;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  & .timePlaceholder {
    color: var(--colorGrey300);
  }

  & .showTimeHolder {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    padding-left: 6px;
    padding-top: 3px;
  }
}

.timeInputField {
  display: flex;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #d8dce6;
  margin-top: 3px;
  bottom: 10px;
  padding: 6px;

  & select {
    background-color: transparent;
    border: none;
    border-radius: 0;
    font-size: 14px;
    box-shadow: none;
    padding: 4px 20px 4px 4px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBNSVQuIE1hZGUgYnkgcmFkaXgtdWk6IGh0dHBzOi8vZ2l0aHViLmNvbS9yYWRpeC11aS9pY29ucyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aAogICAgZmlsbC1ydWxlPSJldmVub2RkIgogICAgY2xpcC1ydWxlPSJldmVub2RkIgogICAgZD0iTTcuNTAwMDkgMC44NzcwMTRDMy44NDI0MSAwLjg3NzAxNCAwLjg3NzI1OCAzLjg0MjE2IDAuODc3MjU4IDcuNDk5ODRDMC44NzcyNTggMTEuMTU3NSAzLjg0MjQgMTQuMTIyNyA3LjUwMDA5IDE0LjEyMjdDMTEuMTU3OCAxNC4xMjI3IDE0LjEyMjkgMTEuMTU3NSAxNC4xMjI5IDcuNDk5ODRDMTQuMTIyOSAzLjg0MjE2IDExLjE1NzcgMC44NzcwMTQgNy41MDAwOSAwLjg3NzAxNFpNMS44MjcyNiA3LjQ5OTg0QzEuODI3MjYgNC4zNjY4MyA0LjM2NzA4IDEuODI3MDEgNy41MDAwOSAxLjgyNzAxQzEwLjYzMzEgMS44MjcwMSAxMy4xNzI5IDQuMzY2ODMgMTMuMTcyOSA3LjQ5OTg0QzEzLjE3MjkgMTAuNjMyOCAxMC42MzMxIDEzLjE3MjcgNy41MDAwOSAxMy4xNzI3QzQuMzY3MDggMTMuMTcyNyAxLjgyNzI2IDEwLjYzMjggMS44MjcyNiA3LjQ5OTg0Wk04IDQuNTAwMDFDOCA0LjIyMzg3IDcuNzc2MTQgNC4wMDAwMSA3LjUgNC4wMDAwMUM3LjIyMzg2IDQuMDAwMDEgNyA0LjIyMzg3IDcgNC41MDAwMVY3LjUwMDAxQzcgNy42MzI2MiA3LjA1MjY4IDcuNzU5OCA3LjE0NjQ1IDcuODUzNTdMOS4xNDY0NSA5Ljg1MzU3QzkuMzQxNzEgMTAuMDQ4OCA5LjY1ODI5IDEwLjA0ODggOS44NTM1NSA5Ljg1MzU3QzEwLjA0ODggOS42NTgzMSAxMC4wNDg4IDkuMzQxNzIgOS44NTM1NSA5LjE0NjQ2TDggNy4yOTI5MVY0LjUwMDAxWiIKICAgIGZpbGw9IiMwMDAwMDAiCiAgLz4KPC9zdmc+);
    background-position: right;
    padding-left: 30px;
  }
}

.locationAutocompleteInput {}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {}

.locationAddress,
.building {
  flex-shrink: 0;
  /* margin-bottom: 24px; */

  @media (--viewportMedium) {
    /* margin-bottom: 32px; */
  }

  &>div {
    &:last-child {
      @media(min-width: 960px) {
        position: absolute;
      }
    }
  }
}

.phoneNumber {

  /* composes: marketplaceModalPasswordMargins from global; */
  & input {
    /* for Firefox */
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.optionStyle {

  & option {
    background-color: #80bdff;
    color: #fff;
    font-weight: 600;
  }
}

.checkField {
  background-color: #fff;
  min-height: 45px;
  position: relative;

  &:focus {
    background-color: #fff;
  }

  &:hover {
    background-color: #fff;
  }
}

.checkInputBox {
  padding: 15px 30px 17px 30px;
  display: block;
  /* pointer-events: none; */
  position: absolute;
  top: 44px;
  z-index: var(--zIndexPopup);
  min-width: 300px;
  margin-top: 7px;
  background-color: #00ac9d;
  border-top: 1px solid var(--colorGrey100);
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadiusMedium);
  transition: var(--transitionStyleButton);

  & ul {
    & li {
      & span {
        color: #fff;
      }
    }
  }
}