.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroSlider {

  & .searchBarSlider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px auto 0 auto;
    width: fit-content;
    gap: 20px;
    padding: 0 20px;

    @media(max-width: 960px) {
      flex-wrap: wrap;
      gap: 10px;
      flex-direction: column;
    }

    & .locationInput {
      position: relative;

      & .searchIcon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(0, -50%);
      }

      & .inputBox {
        padding-left: 35px;
      }
    }

    & .selectDate {
      width: 305px;

      @media(max-width: 1150px) {
        width: auto;
      }

      @media(max-width: 960px) {
        width: 100%;
      }
    }

    & .dropdownBox {
      @media(max-width: 960px) {
        width: 100%;
      }
    }

    & .submitButton {
      @media(max-width: 960px) {
        width: 100%;
      }

      & button {
        background-color: #ffd100;
        border: 1px solid #ffd100;
        padding: 5px 30px;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
        height: 48px;
        cursor: pointer;

        @media(max-width: 960px) {
          font-size: 16px;
          width: 100%;
          height: 40px;
        }

        @media(max-width: 768px) {
          height: 36px;
        }

        @media(max-width: 960px) {}
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  gap: 30px;
  padding: 0 20px;
  margin: 60px auto 0 auto;
  position: relative;
  z-index: 1;
  width: 80%;

  @media(max-width:960px) {
    align-items: center;
    justify-content: center;
  }
}

.activeButton {
  color: #ffffff;
  font-size: 24px;
  /* width: 100px; */
  border: none;
  border-bottom: 4px solid var(--marketplaceColor);
  padding: 0 24px 14px 24px;
  cursor: pointer
}

.inactiveButton {
  color: #ffffff;
  /* width: 100px; */
  font-size: 24px;
  border: none;
  border-bottom: 4px solid transparent;
  padding: 0 24px 14px 24px;
  cursor: pointer
}

.heroWrapper {
  margin: 28px auto 0 auto;
  width: 80%;
  z-index: 9;
  position: relative;

  @media(max-width: 640px) {
    width: 90%;
  }

  @media(max-width: 440px) {
    width: 95%;
  }
}

.heroSwiper {
  text-align: left;
  color: #fff;
  font-size: 50px;
  font-weight: bold;
  line-height: 58px;
  width: 550px;
  margin: auto;

  @media(max-width: 600px) {
    width: 100%;
  }

  @media(max-width: 640px) {
    font-size: 38px;
    line-height: 48px;
  }

  @media(max-width: 440px) {
    font-size: 32px;
    line-height: 42px;
  }
}