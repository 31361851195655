.hidden {
  opacity: 0;
}

.consoleContainer {
  font-family: Khula;
  font-size: 4em;
  text-align: center;
  height: 200px;
  width: 600px;
  display: block;
  position: absolute;
  color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.consoleUnderscore {
  display: inline-block;
  position: relative;
  top: -0.14em;
  left: 10px;
}

.animationText {
	margin-left: 15px;
	background-color: #00ac9d;
	border-right: .1em solid white;
	-webkit-animation:blink-caret .3s step-end infinite alternate;
}

@-webkit-keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}