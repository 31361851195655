@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  & .backgroundOverlay {
    background-color: rgb(0, 0, 0);
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.heroHeading {
  width: 65%;

  @media(max-width: 1440px) {
    width: 85%;
  }

  @media(max-width: 1100px) {
    width: 95%;
  }

  & h1 {
    text-align: center;

    @media(max-width: 460px) {
      text-align: left;
    }
  }
}